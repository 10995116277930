import React from 'react';
import { Link } from 'gatsby';

import PropTypes from 'prop-types';

const ProductCard = ({ data }) => {
  const { version, features, bgColor, inColor, price, action, type } = data;
  //console.log(features);
  return (
    <div
      id='base'
      className={`w-48 m-2 p-2 ${bgColor} text-center rounded
          hover:shadow-md border flex flex-col justify-between`}
    >
      <h3 className=' w-text-lg font-bold mb-2'>{version}</h3>
      <div className={`${inColor}`}>
        {!features ? '' : features.map((f, i) => <div key={i}>{f}</div>)}
      </div>
      <div className=' mt-2'>
        <p className=' '>
          <span className='text-4xl'>{price}</span> руб.
        </p>
        <Link
          to={
            type === 'license'
              ? '/contact'
              : type === 'grm'
              ? '/grm-order'
              : '/fresh-order'
          }
          className=' mt-2 py-2 block uppercase border border-gray-500 
            text-white bg-red-400 hover:bg-red-600 focus:border-white focus:outline-none'
        >
          {action}
        </Link>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  data: PropTypes.object.isRequired
};

export default ProductCard;
