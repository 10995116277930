import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import LayoutP from '../components/layoutP';
import SEO from '../components/seo';
//import Rent from '../components/rent';
import Sale from '../components/sale';

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        about
        sale {
          base {
            version
            features
            price
            bgColor
            type
            action
          }
          prof {
            version
            features
            price
            bgColor
            type
            action
          }
          extend {
            version
            features
            price
            bgColor
            type
            action
          }
        }
        rent {
          base {
            version
            features
            price
            bgColor
            type
            action
          }
          prof {
            version
            features
            price
            bgColor
            type
            action
          }
          extend {
            version
            features
            price
            bgColor
            type
            action
          }
        }
      }
      html
      fields {
        slug
      }
    }
  }
`;

const ProductPage = ({ data }) => {
  const {
    title,
    description,
    about,
    sale,
    rent,
  } = data.markdownRemark.frontmatter;
  return (
    <LayoutP>
      <SEO
        keywords={[
          `1С`,
          `Программы 1С`,
          `Купить 1С Югра`,
          `Купить 1С Сургут`,
          `Купить 1С Пыть-Ях`,
          `софтмакс`,
          `1с Пыть-Ях`,
        ]}
        title={title}
      />

      <section
        className='flex flex-col flex-1  text-center md:justify-center
       mx-auto px-6 py-6 md:py-6 w-full  bg-gray-300'
      >
        <h2 className=' text-3xl mb-4 font-bold'>{title}</h2>
        <p className='mb-4 px-4 text-lg text-left bg-gray-300 '>
          {description}
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        ></div>
        <div className='flex flex-wrap'>
          <div className='flex-auto m-1'>
            <Sale
              data={sale}
              title={'Купить лицензию'}
              subtitle={'программа устанавливается на локальный ПК'}
            />
          </div>
          <div className='flex-auto m-1'>
            <Sale
              data={rent}
              title={'Арендовать в облаке 1С'}
              subtitle={'программа работает на удаленном сервере'}
            />
          </div>
        </div>
        <p className='mt-6'>{about}</p>
      </section>
    </LayoutP>
  );
};

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProductPage;
