import React from 'react';

import PropTypes from 'prop-types';

import ProductCard from './productCard';

const Sale = ({ data, title, subtitle }) => {
  return (
    <div
      className='flex flex-col  bg-white
     px-1 py-2 border border-gray-300 rounded-md text-center'
    >
      <h2 className=' text-2xl text-red-700 font-bold'>{title}</h2>
      <h3 className=' text-base mt-2 mb-4 text-blue-700 '>{subtitle}</h3>
      <div id='base-prof' className='flex flex-wrap justify-center'>
        <ProductCard data={data.base} />
        <ProductCard data={data.prof} />
        <ProductCard data={data.extend} />
      </div>
    </div>
  );
};

Sale.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Sale;
