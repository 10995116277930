import PropTypes from 'prop-types';

import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import dogIllustration from '../images/dog-illustration.svg';

function LayoutP({ children }) {
  return (
    <Layout>
      <SEO
        keywords={[
          `1С`,
          `Программы 1С`,
          `О компании`,
          `софтмакс`,
          `1с Пыть-Ях`,
        ]}
        title='Продукты'
      />
      <section className=' bg-gray-500 flex-1 flex flex-col '>
        <nav className='flex justify-evenly'>
          <Link to='/products/bp'>1C:БП</Link>
          <Link to='/products/zup'>1С:ЗУП</Link>
          <Link to='/products/unf'>1С:УНФ</Link>
          <Link to='/products/rt'>1С:РТ</Link>
          <Link to='/products/dog'>1С:ДОГ</Link>
          <Link to='/products/kas'>1С:КАС</Link>
        </nav>
        <main className='flex-1 bg-gray-300'>{children}</main>
      </section>
    </Layout>
  );
}
LayoutP.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutP;
